.cookies {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100000;
  box-shadow: 0px 0px 6px #7e7e7e;
}

.cookies_content {
  background-color: var(--deeper-blue);
  padding: 10px 0px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  text-align: center;
  flex-direction: row;
}
.cookies_content p {
  padding: 0px 10px;
  margin: 2.5px 0px;
}
.cookies_content button {
  margin: 5px;
  margin-top: 5px;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: var(--white);
  vertical-align: baseline;
}
.cookies_content a {
  margin: 5px;
  margin-top: 5px;
  padding: 5.5px 10px;
  border-radius: 5px;
  background-color: var(--white);
}

.cookies_content span {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  color: var(--deeper-blue);
  font-family: Montserrat;
}
@media(max-width:767px) {
  .cookies_content {
    flex-direction: column;
  }
}