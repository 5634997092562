.response {
  color: var(--deeper-blue);
  text-align: center;
  text-transform: uppercase;
  font-size: var(--fs-small);
}

.questions {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.goto_enquiry {
  font-size: var(--fs-small);
  margin-top: 8px;
  color: var(--deeper-blue);
}

.no_margin {
  margin: 0px;
}


.feedback_form {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}
.feedback_form button {
  margin-top: 10px;
  padding: 6px;
}

.feedback_form textarea{
  font-size: var(--fs-small);
}

.feedbackform_input_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.feedbackform_buttons_container{
  margin: 5px 20px 15px 0;
}

.feedbackform_buttons_container input[type="radio"]:checked+label,
.Checked+label {
  background-color: var(--deep-blue);
  color: white;
}
.feedbackform_buttons_container input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
  width: 115px;
  height: 40px;
  position: absolute;
  cursor: pointer;
}

.feedbackform_buttons_container  label {
  padding: 0px 20px;
  cursor: pointer;
  z-index: 90;
  border-radius: 4px;
  border: none;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  font-size: var(--fs-small);
  text-align: center;
  color: var(--deep-blue);
  white-space: normal;
  background-color: white;
  box-shadow: 3px 3px 3px 3px #B7B7B729;
}
.popup_header{
  width: 96%;
  font-size: var(--fs-base);
  font-weight: 700;
  text-align: center;
  margin: var(--sp-small) 5px;
}
.form_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_container p{
  line-height: 18px;
}
.info{
  display: block;
  line-height: 18px;
  margin: 0 5px 10px;
  text-align: left;
  font-size: var(--fs-small);
}
@media (max-width: 767px) {
  .feedbackform_buttons_container input[type="radio"] {
    width: 75px;
    height: 35px;
  }
  .feedbackform_buttons_container  label {
    width: 35px;
    height: 35px;
  }
  .feedbackform_buttons_container{
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
