.field {
  padding: 10px 15px;
  background-color: #EDF3FF;
  font-size: var(--fs-base);
  color: #250A94;
  border: none;
  border-radius: 5px;
  font-family: inherit;
}

.input_field{
  width: 100%;
}

.password_icon{
  width: 35px;
  margin-right: var(--sp-small);
}

.password_icon img{
  height: 100%;
  width: 100%;
}

.input_container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF3FF;
  border-radius: 5px;
  color: #250A94;
}