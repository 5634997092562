.button {
  background-color: var(--deeper-blue);
  padding: 12px 34px;
  border-radius: 27px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}

.button_content{
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_highlight {
  background-color: var(--white);
  border-radius: 5px;
  min-width: 0;
}

.button_primary {
  background-color: var(--white);
}

.button_small , .button_extra small {
  padding: var(--sp-tiny);
  background-color: var(--deep-blue);
  min-width: auto;
}

.button_secondary{
  background-color: yellow;
  border-radius: 5px;
}

.button_dark_blue{
  background-color: #00074e;
  border-radius: 5px;
  width: 164px;
  line-height: 2.24;
  padding: 0px 44px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.24;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.button_secondary_blue{
  background: #100889 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.button_secondary_red{
  background: #FF1B00 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.button_gold{
  background: #FF952A 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 0px 22px;
}

.button_text {
  color: var(--white);
  font-size: var(--fs-highlight);
  text-transform: uppercase;
  font-weight: 900;
}

.button_text_highlight {
  color: var(--deeper-blue);
}

.button_text_primary, .button_text_secondary {
  color: var(--deeper-blue);
}

.button_text_small {
  font-size: var(--fs-base);
}
.button_text_extra_small {
  font-size: var(--fs-small);
}

.button_text_gold{
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 600;
}

.icon{
  height: 100%;
  width: 100%;
}

.icon_container{
  height: 30px;
  width: 30px;
  margin: auto var(--sp-medium) auto auto;
}
.button_ternary{
  background-color: #e60150;
  border-radius: 5px;
  width: 130px;
  margin: 10px 0px;
  padding: var(--sp-medium) var(--sp-large);
}
.button_text_ternary {
  font-size: var(--fs-base);
}
.button_text_dark_blue{
  font-size: 1.5625rem;
  text-transform: capitalize;
  font-weight: 600;
}
.button_disabled {
  cursor: default;
  opacity: 0.5;
  cursor: not-allowed;
}
@media (max-width: 767px) {
  .button_small {
    min-width: 0;
  }
  .button_ternary {
    width: 90px;
  }
  .button_dark_blue {
    padding: 0px 20px;
  }
}