.section {
  display: block;
  width: 100%;
  padding: var(--sp-huge) 0;
  color: var(--deep-blue);
}

.section_highlight {
  background: transparent
    linear-gradient(24deg, #00095e 21%, #00042c 53%, #000000 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white);
}

.section_deeper-blue {
  background-color: #010354;
}

.section_purple {
  background-image: linear-gradient(
    307deg,
    #45108a 98%,
    #3d065f 70%,
    #12054e -1%,
    #10054d -1%
  );
}

.section_wheatish {
  background-color: rgba(234, 242, 188, 0.16);
}

.section_grey {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.section_primary {
  background: transparent
    radial-gradient(
      closest-side at 11% -23%,
      #4b02ef 0%,
      #4c02f1 0%,
      #4c02f1 0%,
      #3802c3 13%,
      #29029e 25%,
      #1c037f 38%,
      #120367 51%,
      #0b0357 65%,
      #07034d 81%,
      #06044a 100%
    )
    0% 0% no-repeat padding-box;
  color: var(--white);
}

.section_alternate {
  background: #f8f8f8 0% 0% no-repeat padding-box;
}

.section_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: var(--sp-large) 0;
  text-transform: uppercase;
  font-size: var(--fs-header);
  text-align: center;
  line-height: 70px;
  font-weight: bold;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.section_header_default {
  color: var(--deeper-blue);
}

.section_header_primary {
  color: var(--white);
}

.section_content {
  display: block;
  width: 100%;
  margin: var(--sp-large) 0;
  line-height: 34px;
  font-size: var(--fs-highlight);
}

.section_alternatePinkBlue {
  background: linear-gradient(180deg, #e60150 35%, #00074e 35%);
}

.section_lightBlue {
  background-color: #eaf4ff;
}

.section_content_no_padding {
  padding: 0px;
}

.section_content_allow_padding {
  padding: 0px var(--sp-large);
}

.section_light_blue {
  background-color: #fafcff;
}

@media (max-width: 767px) {
  .section {
    padding: var(--sp-large) 0;
  }

  .section_header {
    line-height: 40px;
  }

  .section_content {
    line-height: 24px;
  }
}

@media (max-width: 575px) {
  .section_header {
    font-size: 2rem;
  }
}

.section_no_padding {
  padding: 0px;
}
