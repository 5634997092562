.header {
  background-color: var(--white);
  padding: 10px 0;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  box-shadow: 0px 3px 6px #00000029;
}

.header img {
  max-width: 100%;
  max-height: 100%;
}

.footer {
  padding: 50px 0;
}

@media (max-width: 767px) {
  .header {
    height: 50px;
  }
}