.popup {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  animation: visibility 0.5s linear;
}

.popup_content {
  background-color: var(--off-white);
  border-radius: 10px;
  padding: 15px;
  position: relative;
  width: 70%;
  max-width: 400px;
  box-shadow: 0px 0px 2px #000029;
  margin: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.minimize {
  background-color: var(--deeper-blue);
  position: absolute;
  right: -3px;
  top: -3px;
  color: var(--off-white);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  border-radius: 15px;
  cursor: pointer;
  font-weight: bold;
}
.smaller {
  font-size: 0.7rem;
  margin: 8px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.bigger {
  width: 55px;
  height: 55px;
  border-radius: 32.5px;
  top: -5px;
  right: 0px;
  border: 1px solid var(--deeper-blue);
  font-size: 2rem;
  box-shadow: 0px 0px 6px #7e7e7e;
  padding: 5px;
}
.popup_content p{
  margin: 5px;
  text-align: left;
}
.para{  
  line-height: var(--sp-large);
}

.popup_content button {
  margin: 5px;
  margin-top: 10px;
  padding: 8px 20px;
  border-radius: 5px;
  vertical-align: baseline;
  text-decoration: none;
  text-align: center;
  background-color: var(--deeper-blue);
}
.popup_content a {
  margin: 5px;
  margin-top: 10px;
  padding: 8px 20px;
  display: initial;
  border-radius: 5px;
  text-decoration: none;
  background-color: var(--deeper-blue);
  text-align: center;
}
.popup_button_container span{
  text-transform: capitalize;
  font-weight: 500;
  color: var(--white);
  font-size: 1rem;
  font-family: Montserrat;
}

.popup_content_minimized {
  margin: 0px;
  padding: 0px;
  width: 55px;
  height: 55px;
  border-radius: 27.5px;
  margin: 20px;
}

.feedback_popup{
  padding: 20px;
}
.feedback_popup button{
  border-radius: 27px;
  margin-left: 0;
  margin-right: 0;
}
.feedback_popup a{
  margin: 0;
  padding: 0;
  border-radius: 0;
  text-decoration: underline;
  background-color: inherit;
  text-align: left;
}

@keyframes visibility{
  0%{
    opacity:0;
  }
  100%{
    opacity: 1;
  }
}
@media(max-width:767px) {
  .bigger {
    font-size: 2.5rem;
  }
}
@media(max-width:576px) {
  .popup_content {
    width: 65%;
    max-width: 350px;
  }
  .popup_content_minimized {
    width: 55px;
  }
}

 