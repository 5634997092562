.desktop_container {
  display: block;
  width: 100%;
}

.desktop_links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.left_links{
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_links{
  flex: 1;
   display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  text-decoration: none;
  font-size: var(--fs-base);
  font-weight: bold;
  color: var(--deeper-blue);
  text-transform: uppercase;
  text-align: center;
  padding-bottom: var(--sp-tiny);
}

.link_register{
  text-decoration: none;
  font-size: var(--fs-base);
  font-weight: bold;
  background-color: var(--deeper-blue);
  color:white;
  text-transform: uppercase;
  text-align: center;
  padding: var(--sp-small) var(--sp-medium)
}

.logo_link{
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.menu_icon{
  cursor: pointer;
  font-size: 5rem;
}

.mobile_container{
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.parent_mobile_container{
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
}

.close_menu{
  display: none;
}

.show_menu{
  display: flex;
}

.logo{
  width: 100px;
}

.links_container{
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  background-color: white;
  z-index: 10000;
  box-shadow: 0px 3px 6px #00000029;
}

.active_text{
  border-bottom: 3px solid var(--deeper-blue);
}

.inactive_text{
 border-bottom: none;
}

.registration_floater{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: white;
  width: 92%;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
}

.floater_center {
  text-align: center;
  width: 100%;
}

.floater_center span:nth-child(2){
  color: #FF9D00;
}

.floater_center a {
  color: white;
}

.floater_desc_container{
  line-height: 25px;
}


.floater_desc_container span:nth-child(2){
  color: #FF9D00;
}

.registration_floater_container{
  padding: var(--sp-medium) 0px;
  position: fixed;
  z-index: 200;
  width: 100%;
  background-color: var(--deeper-blue);
  transition: top 0.4s;
}

 .not_visible{
  top: -100px;
}

.visible{
  top:100px;
}

.clock_rem_holder{
  width: 30px;
  height: 30px;
  background-color: var(--white);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floater_timer_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-base);
}

.floater_timer_container span{
  margin-right: 10px;
}

.floater_timer_container p{
  margin: 0px 6px;
}

.clock_rem_holder p{
  color: var(--deeper-blue);
}

.clock_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media (max-width: 992px){
  .link{
    font-size: 0.82rem;
  }
  .link_register{
    font-size: 0.82rem;
  }
}

@media (max-width: 767px){
  .parent_mobile_container{
    display: flex;
  }

  .desktop_container{
    display: none;
  }

  .link{
    text-align: center;
    margin-bottom: var(--sp-small);
    font-size: var(--fs-base);
    align-self: center;
    padding-top: 10px;
  }

  .logo{
    width: 70px;
  }

  .logo_link{
    justify-content: start;
  }


  .link_register{
    align-self: center;
    margin-bottom: 10px;
    font-size: var(--fs-base);
  }

  .registration_floater{
    flex-direction: column;
  }

  .floater_timer_container{
    margin-top: 20px;
  }

  .visible{
    top: 70px;
  }

  .clock_rem_holder {
    width: 20px;
    height: 20px;
  }
}