.fallback_container {
  animation: fadeout 3s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.fallback_container_image {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fallback_container_image img {
  max-width: 100%;
  max-height: 100%;
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}
