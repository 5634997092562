.more {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--deeper-blue);
}

.more_about {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 60%;
  line-height: 30px;
  padding: 0px var(--sp-large) 0px 0px
}

.more_about img {
  max-width: 200px;
}

.more_links_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-basis: 40%;
  width: 100%;
}

.more_links {
  line-height: 30px;
  color: var(--deeper-blue);
}

.more_links li:first-of-type {
  font-weight: bold;
  text-transform: uppercase;
}

.more_links a {
  text-decoration: none;
  color: var(--deeper-blue);
}

.initiative {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.initiative_logo {
  height: 100px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.initiative_logo img {
  max-width: 100%;
  max-height: 100%;
}

.initiative_text {
  text-transform: uppercase;
  text-align: center;
  color: var(--deeper-blue);
}

.initiative_text b{
  font-weight: 600;
}

.initiative_text a {
  text-decoration: none;
  color: var(--deeper-blue);
}

.social_icon {
  width: 20px;
  height: 20px;
  margin-right: 24px;
}

@media (max-width: 767px) {
  .more {
    flex-direction: column;
    text-align: center;
  }

  .more_about {
    flex-direction: column;
    line-height: 20px;
    padding: 0px;
  }

  .more_links_wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .more_links {
    line-height: 24px;
  }
}